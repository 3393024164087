/**
 * Format a number with Swedish locale
 */
const formatNumber = (
  value,
  {
    useGrouping = true,
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
    ...options
  } = {},
) => {
  if (minimumFractionDigits > maximumFractionDigits) {
    throw new Error(
      'minimumFractionDigits cannot be larger than maximumFractionDigits',
    );
  }

  let result;
  try {
    result = new Intl.NumberFormat('sv-SE', {
      useGrouping,
      minimumFractionDigits,
      maximumFractionDigits,
      ...options,
    }).format(value);
  } catch {
    result = value != null ? String(value) : value;
  }
  return result;
};

export default formatNumber;
