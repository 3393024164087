import formatNumber from './formatNumber';
import isNumber from './isNumber';

const formatMoney = (valueInCent, rounded = false) => {
  if (!isNumber(valueInCent)) {
    return valueInCent;
  }

  return `${formatNumber(valueInCent / 100, {
    minimumFractionDigits: rounded ? 0 : 2,
    maximumFractionDigits: rounded ? 0 : 2,
  }).replace(/\D00$/, '')} kr`;
};

export default formatMoney;
